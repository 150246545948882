import { postServerRequest } from '../../helpers/serverRequest';

export async function loginServer(httpURL, config) {
    const url = `${httpURL}/login`;
    return await postServerRequest(url, config);
}

export async function loginTemporalServer(httpURL, data, config) {
    const url = `${httpURL}/loginTemporal`;
    return await postServerRequest(url, data, config);
}