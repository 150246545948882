<template>
  <v-app id="inspire">
    <notifications group="gral_notif" position="bottom right" :width="400" />
    <v-main>
      <v-container fluid fill-height class="fondoApp">
        <v-layout align-center justify-center>
          <v-flex xs12 sm7 md5>
            <v-form v-on:submit.prevent>
              <v-card class="elevation-12">
              
                <v-toolbar dense dark color="orange">
                  <v-spacer></v-spacer>
                  <v-toolbar-title class="text-md-center"
                    >El servicio de SIIAU se encuentra inactivo </v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                </v-toolbar>
        
                   <v-card-text class="text-center">
                  <div style="padding-top: 1px" class="text-center">
                   
                 
                  Puedes ingresar al sistema pero algunas funciones pueden no trabajar correctamente hasta que SIIAU se reestablezca.
                 
                  </div>
                   </v-card-text>

                  <v-row>
                 
                    <v-col cols="9">
                      <v-text-field
                        v-model="codigo"
                        prepend-icon="mdi-account"
                        label="Código SIIAU"
                        type="text"
                        :disabled="loading"
                        color="blue-grey"
                        outlined
                        dense
                        :error-messages="codigoErrors"
                        @input="$v.codigo.$touch()"
                        @blur="$v.codigo.$touch()"
                      >
                      </v-text-field>
                      <p class="errorMessage">{{ errorMessage }}</p>
                    </v-col>
          

                    <v-col cols="3">
         
                    <v-btn
                      type="submit"
                      color="orange"
                      @click="submit()"
                      dark
                      :loading="loading"
                      >Iniciar sesión</v-btn
                    >
                  </v-col>
     
           
                </v-row>

                <v-card-text class="text-center">
                  <div style="padding-top: 1px" class="text-center">
                    <a style="text-decoration: none; font-size: 12px" class="link" :href="`http://transparencia.udg.mx/aviso-confidencialidad-integral`" target="_blank">Aviso de privacidad</a>
                  </div>
                </v-card-text>
                <v-card-text class="text-right">
                  <i>Versión {{ systemVersion }}</i>
                </v-card-text>
              </v-card>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

import { loginTemporalServer } from "./login.service";

export default {
  name: "loginView",
  mixins: [validationMixin],
  components: {},
  computed: {
    ...mapGetters(["httpURL", "sessionToken", "systemVersion"]),
    codigoErrors() {
      const errors = [];
      if (!this.$v.codigo.$dirty) return errors;
      !this.$v.codigo.required && errors.push("Campo requerido.");
      return errors;
    },
  },
  validations: {
    codigo: { required, minLength: minLength(5) },
    
  },
  data() {
    return {
      loading: false,
      codigo: "",
     
      errorMessage: "",
    };
  },
  created() {
    if (localStorage.getItem("sg-token")) {
      const token = localStorage.getItem("sg-token");
      this.$store.dispatch("setSessionToken", token);
      this.$router.push("/apps");
    }
  },
  methods: {
    async submit() {
      if (this.camposValidos()) {
        try {
          this.loading = true;
          const config = { headers: {} };
          const data = {
            codigo: this.codigo,
          };
          const serverResponse = await loginTemporalServer(this.httpURL, data, config);
          this.$validateResponse(serverResponse);
          this.loading = false;
          if (serverResponse.ok) {
            localStorage.setItem("sg-token", serverResponse.token);
            this.$store.dispatch("setSessionToken", serverResponse.token);
            this.$store.dispatch("setUserName", serverResponse.userName);
            this.$store.dispatch("setCodigoUsuario", this.codigo);
            this.$router.push("/apps");
          } 
          else {

            this.errorMessage = serverResponse.mensaje;
          }
        } catch (error) {
          console.log(error)
          this.loading = false;
          this.$notificarError(error);
        }
      }
    },
    camposValidos() {
      this.$v.$touch();
      return !this.$v.codigo.$invalid
    },
  },
};
</script>

<style>
.fondoApp {
  background: #b0bec5;
}
.errorMessage {
  color: #ff0000;
}
.invalid_input {
  border: 1px solid red;
}
</style>
